body {
  background-color: #1a1a1a;
}

.chat-item-right .chat-item-content {
  background-color: #4e4e4e;
}

.chat {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.chat-item-right {
  justify-content: flex-end;
}

.chat-item-left {
  justify-content: flex-start;
}

.rce-avatar-container.default {
  width: 5% !important;
  height: 5% !important;
}


.chat-container {
  display: flex;
  flex-direction: column;
   height: 90vh;
}
@media screen and (max-width: 768px) {
  .rce-avatar-container.default {
    width: 15% !important;
    height: 15% !important;
  }

  .chat-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90vh;
  }
} 


/*
.chat-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}*/
.message-list {
  flex-grow: 1;
  overflow-y: auto;
}

.input-container {
  padding: 0 2% 1%;
  margin-top: auto;
}
.rce-input {
  border-radius: 20px !important;
}
.custom-input {
  border-radius: 20px !important;
  border: 1px solid #ccc !important;
}
.rce-mbox-right {
  background-color: #025c4c !important;
  color: #fff !important;
}
.rce-mbox-right-notch {
  fill: #025c4c !important;
}
